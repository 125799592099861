export const getOverviewData = () => {
    return [
        {
            // sectionImage: 'Overview/credit-one-bank-club-logo.png',
            sectionTitle: 'North Endzone Club',
            paragraph: 'Redefine what’s possible with existing technological features and expansive views of Allegiant Stadium.',
            area: '10,400',
            reception: '650',
            banquet: '100'
        }
    ]
}

export const getCarouselImages = () => {
    return [
        'FieldLevelEndzone/Photos/field-level-endzone-club-bg.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-01.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-04.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-02.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-03.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-05.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-06.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-07.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-08.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-09.jpg',
        'FieldLevelEndzone/Photos/field-level-endzone-club-10.jpg'
    ]
}

export const getFieldViewImages = () => {
    return [
        'FieldLevelEndzone/FieldView/VR_Section_101_16_12_Black.jpg',
        'FieldLevelEndzone/FieldView/VR_Section_101_16_12_Down.jpg',
        'FieldLevelEndzone/FieldView/VR_Section_101_16_12_Front.jpg',
        'FieldLevelEndzone/FieldView/VR_Section_101_16_12_Left.jpg',
        'FieldLevelEndzone/FieldView/VR_Section_101_16_12_Right.jpg',
        'FieldLevelEndzone/FieldView/VR_Section_101_16_12_Up.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
        'AllegiantFlightDeck/Layout/placeholder-layout.jpg'
    ]
}